// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "@hotwired/stimulus"

import "bootstrap"
import "moment"

import "chartkick/chart.js"

import "controllers"
import "trix"
import "@rails/actiontext"

// Turbo tuning
document.addEventListener("turbo:before-cache", function() {
  document.getElementById("remote-modal").innerHTML = ""
})

// document.addEventListener("turbo:frame-missing", (event) => {
//   console.log("Frame Missing!")
//   const { detail: { response, visit } } = event;
//   event.preventDefault();
//   visit(response.url);
// });

// Temporary solution for non-re-rendering editors
document.addEventListener("turbo:before-render", (event) => {
  // console.log("MRG", event)
  // if (event.detail.renderMethod == "morph") {
  document.querySelectorAll("turbo-frame.editor").forEach((e) => e.removeAttribute("src"))
  // }
});

document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response, visit } } = event;

  response.text().then((html) => {
    console.log("Frame missing action", response)
    document.getElementById("app").innerHTML = html
    window.history.pushState({},'',response.url);
  })

  // Hiding MODAL - workaround for Logging in with 2FA
  

  event.preventDefault();
  // visit(response.url);
});
